$(function () {
    // This method is used to add/remove the "open" class for the calendar dropdown as well as set the aria-expanded property on the parent div appropriately to the "open" state
    $(document).on('focusin', () => {
        const calendarClasses = ["add-to-calendar-btn", "add-to-calendar", "apple-calendar", "google-calendar", "outlook-calendar", "365-calendar", "other-calendar"];
        if (!calendarClasses.includes(document.activeElement.id)) {
            if ($(".add-to-calendar").hasClass("open")) {
                $(".add-to-calendar").removeClass("open");
                $(this).attr("aria-expanded", false);
            }
            else {
                $(this).attr("aria-expanded", true);
            }
        }
    });

    // This method is used to update the "aria-expanded" attribute on the parent Div so that click off/away from the calendar button will update the aria-expanded to false
    $(document).click(function () {
        if (!$(".add-to-calendar").hasClass("open")) {
            $(".add-to-calendar").attr("aria-expanded", false);
        }
    });

    // This method is used to update the "aria-expanded" attribute on the parent Div so that mouse events to open, as well as child item clicks that close the menu, will 
    // update the aria-expanded value appropriately
    $(".add-to-calendar").click(function () {
        if ($(".add-to-calendar").hasClass("open")) {
            $(this).attr("aria-expanded", false);
        }
        else {
            $(this).attr("aria-expanded", true);
        }
    });
});